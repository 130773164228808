<!--
 * @Description: 合同
 * @Author: zhang zhen
 * @Date: 2023-02-15 17:20:13
 * @LastEditors: zhang zhen
 * @LastEditTime: 2024-09-23 17:12:10
 * @FilePath: /page-sass/src/views/requirement/orderManagement/modules/children/contractPart.vue
-->
<template>
  <div class="UploadProgress">
    <FileListView ref="FileListViewRef" />
  </div>
</template>

<script>
import EmptyArea from '@/components/plugins/EmptyArea.vue'
import UploadDrag from '@/components/plugins/uploadDrag'
import { getAction } from '@/api/manage'
import FileListView from './fileListView.vue'

export default {
  name: 'ContractPart',
  props: {
    orderNo: {
      type: String,
      default: ''
    },
    orderStatus: {
      type: [String, Number],
      default: '1'
    },
    fileKey: String
  },
  components: {
    UploadDrag,
    EmptyArea,
    FileListView
  },
  data() {
    return {
      uploadFiles: [],
      contractId: '',
      contractNo: '',
      contractFileCustomer: '',
      contractFileSupplier: ''
    }
  },
  methods: {
    handleLoadInfo() {
      getAction('/v1/contract/queryByBizOrderNo', {
        bizOrderNo: this.orderNo
      }).then(res => {
        const { success, data, message } = res
        if (success && data && data.length) {
          const { id, contractNo, contractFileCustomer, contractFileSupplier, contractFile } = data[0]
          // this.contractFileCustomer = contractFileCustomer;
          // this.contractFileSupplier = contractFileSupplier;
          this.contractId = id || ''
          this.contractNo = contractNo || ''
          let fileObject = {};
          // contractFileCustomer && (fileObject[contractFileCustomer] = '采购商合同')
          // contractFileSupplier && (fileObject[contractFileSupplier] = '供应商合同')
          contractFile && (fileObject[contractFile] = '协议文件')
          this.$nextTick(_ => {
            this.$refs.FileListViewRef.handleGetFileList([contractFile], fileObject)
          })
        }
      })
    },
    handleDownload(url) {
      if (!url) return this.$message.warning('没有合同信息')
      window.open(url)
    }
  }
}
</script>

<style lang="less" scoped>
.UploadProgress {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  grid-gap: 24px 24px;
  &-item {
    width: 354px;
    height: 208px;
    background: #f7f8fa;
    border-radius: 8px;
    padding: 24px;
    display: flex;
    align-items: flex-start;
    img.cover {
      width: 200px;
      height: 160px;
      border-radius: 8px;
      margin-right: 14px;
    }
    .infos {
      .title {
        font-weight: 500;
        font-size: 16px;
        color: #222;
      }
      .time {
        color: #595959;
        font-size: 12px;
        margin-top: 4px;
      }
      .option {
        color: #ff6e2d;
        font-size: 12px;
        display: flex;
        align-items: center;
        cursor: pointer;
        span {
          margin-left: 5px;
        }
      }
    }
  }
}
</style>
