<!--
 * @Description: 付款信息表单
 * @Author: zhang zhen
 * @Date: 2023-05-03 16:03:15
 * @LastEditors: zhang zhen
 * @LastEditTime: 2023-05-24 09:20:31
 * @FilePath: /page-sass/src/views/requirement/orderManagement/modules/children/payVoForm.vue
-->
<template>
  <a-modal
    title="支付单信息编辑"
    :visible="visible"
    destroyOnClose
    :confirm-loading="confirmLoading"
    @ok="handleOk"
    @cancel="handleCancel"
  >
    <a-form-model
      v-bind="{
        labelCol: { span: 9 },
        wrapperCol: { span: 15 }
      }"
      ref="infoForm"
      :model="form"
    >
      <a-form-model-item label="付款方式:" labelAlign="left" prop="payMode">
        <a-select v-model="form.payMode" style="width: 180px" placeholder="请选择付款方式">
          <a-select-option :value="0">
            转账
          </a-select-option>
          <a-select-option :value="1">
            现金
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="商品总额:" labelAlign="left" prop="goodsAmount">
        <a-input placeholder="请输入商品总额" v-model="form.goodsAmount"></a-input>
      </a-form-model-item>
      <a-form-model-item label="发货数量:" labelAlign="left" prop="transportCount">
        <a-input placeholder="请输入发货数量" v-model="form.transportCount" suffix="件"></a-input>
      </a-form-model-item>
      <a-form-model-item label="应付金额:" labelAlign="left" prop="payables">
        <a-input placeholder="请输入应付金额" v-model="form.payables"></a-input>
      </a-form-model-item>
      <a-form-model-item label="运费总额:" labelAlign="left" prop="transportFee">
        <a-input placeholder="请输入运费总额" v-model="form.transportFee"></a-input>
      </a-form-model-item>
      <!-- （0-无发票，1-收据，2-电子发票，3-服务发票，4-增值税专用发票，5-增值税普通发票） -->
      <a-form-model-item label="发票类型:" labelAlign="left" prop="fapiaoType">
        <a-select v-model="form.fapiaoType" style="width: 180px" placeholder="请选择发票类型">
          <a-select-option :value="0">
            无发票
          </a-select-option>
          <a-select-option :value="1">
            收据
          </a-select-option>
          <a-select-option :value="2">
            电子发票
          </a-select-option>
          <a-select-option :value="3">
            服务发票
          </a-select-option>
          <a-select-option :value="4">
            增值税专用发票
          </a-select-option>
          <a-select-option :value="5">
            增值税普通发票
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="备注:" labelAlign="left">
        <a-textarea
          placeholder="请输入备注"
          allow-clear
          v-model="form.description"
          :autosize="{ minRows: 6, maxRows: 6 }"
        />
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>
import { getAction, postAction } from '@/api/manage'
export default {
  name: 'payVoForm',
  data() {
    return {
      visible: false,
      confirmLoading: false,
      form: {},
      // rules: {
      //   transportCount: { required: true, message: '请输入发货数量' },
      //   payable: { required: true, message: '请输入应付金额' },
      //   transportFee: { required: true, message: '请输入运费总额' },
      //   fapiaoType: { required: true, message: '请选择发票类型' },
      //   goodsAmount: { required: true, message: '请输入商品总额' },
      //   payMode: { required: true, message: '请选择付款方式' }
      // },
      regionOptions: [],
      orderNo: null
    }
  },
  methods: {
    handleEdit(orderNo) {
      this.visible = true
      this.orderNo = orderNo
      getAction('/order/pay/get', {
        orderNo
      }).then(res => {
        const { success, message, data } = res
        if (success) {
          this.form = Object.assign({}, data)
        } else {
          this.$message.warning(message)
        }
      })
    },
    handleOk() {
      this.$refs['infoForm'].validate(valid => {
        if (valid) {
          const { deliveryAddress } = this.form
          this.confirmLoading = true
          postAction('/order/pay/info', {
            ...this.form,
            orderNo: this.orderNo
          }).then(res => {
            const { success, message } = res
            if (success) {
              // this.$message.success(message)
              this.$emit('ok')
              this.handleCancel()
              this.confirmLoading = false
            } else {
              this.$message.warning(message)
            }
          })
        }
      })
    },
    handleCancel() {
      this.form = {}
      this.visible = false
      this.confirmLoading = false
    }
  }
}
</script>

<style lang="less" scoped></style>
