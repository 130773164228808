import { render, staticRenderFns } from "./returnMoneySuccess.vue?vue&type=template&id=6cd204bd&scoped=true"
import script from "./returnMoneySuccess.vue?vue&type=script&lang=js"
export * from "./returnMoneySuccess.vue?vue&type=script&lang=js"
import style0 from "./returnMoneySuccess.vue?vue&type=style&index=0&id=6cd204bd&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6cd204bd",
  null
  
)

export default component.exports