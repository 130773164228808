<!--
 * @Description: 评价信息
 * @Author: zhang zhen
 * @Date: 2023-08-12 14:17:45
 * @LastEditors: zhang zhen
 * @LastEditTime: 2024-09-24 15:37:47
 * @FilePath: /page-sass/src/views/requirement/orderManagement/modules/children/appraisalInfo.vue
-->
<template>
  <a-tabs v-model="activeKey" tab-position="left">
    <a-tab-pane key="1" tab="我的评价">
      <a-empty class="newEmpty" image="/userPin.png" description="暂无评论" v-if="!hasMine" />
      <template v-else>
        <h2 class="info-title"><span class="title">我的评价</span></h2>
        <div class="content-view">
          <div class="content-view-review">
            <div class="goods-info-review">
              <div class="star-info">
                <span class="label">满意度</span>
                <a-rate v-model="info.wholeEvaluationStar" :tooltips="desc" disabled />
              </div>
              <div class="star-info">
                <span class="label">其他评价</span>
              </div>
              <div class="tagList">
                <!-- <div class="tagList-item" :class="chooseItems.includes(i.value) && 'active'" v-for="i in tagList" :key="i.value">{{ i.label || '' }}</div> -->
                <div class="tagList-item active" v-for="i in tagList" :key="i.tagKey">{{ i.tagName || '' }}</div>
              </div>
              <!-- 图片展示重新构建 -->
              <div class="imageArea" v-if="info.attachments && info.attachments.length">
                <div class="imageArea-item" v-for="(item, index) in info.attachments" :key="index + 'cover'">
                  <img :src="item" alt="" :preview="index" />
                </div>
              </div>
              <a-empty :image="simpleImage" description="暂无评论图片" v-if="!info.attachments || !info.attachments.length" />
              <div class="text-area">
                <a-textarea :autosize="{ minRows: 6, maxRows: 8 }" :maxLength="500" placeholder="谈谈您对产品的建议,我们会努力改进~"
                  v-model="info.content" readonly />
                <span class="number-info">{{ info.content.length }}/500</span>
              </div>
            </div>
          </div>
        </div>
        <template>
          <h2 class="info-title" style="margin-top: 15px">
            <span class="title">对方回复</span>
          </h2>
          <a-textarea style="width: 100%" :autosize="{ minRows: 6, maxRows: 8 }" :maxLength="500" placeholder="回复"
            v-model="info.replyContext" :readonly="true" />
        </template>
      </template>
    </a-tab-pane>
    <a-tab-pane key="2" tab="对方评价">
      <a-empty class="newEmpty" image="/userPin.png" description="暂无评论" v-if="!hasOther" />
      <template v-else>
        <h2 class="info-title"><span class="title">对方评价</span></h2>
        <div class="content-view">
          <div class="content-view-review">
            <div class="goods-info-review">
              <div class="star-info">
                <span class="label">满意度</span>
                <a-rate v-model="otherInfo.wholeEvaluationStar" :tooltips="desc" disabled />
              </div>
              <div class="star-info">
                <span class="label">其他评价</span>
              </div>
              <div class="tagList">
                <!-- <div class="tagList-item" :class="chooseItems.includes(i.value) && 'active'" v-for="i in tagList" :key="i.value">{{ i.label || '' }}</div> -->
                <div class="tagList-item active" v-for="i in otherTagList" :key="i.tagKey">{{ i.tagName || '' }}</div>
              </div>
              <!-- 图片展示重新构建 -->
              <div class="imageArea" v-if="otherInfo.attachments && otherInfo.attachments.length">
                <div class="imageArea-item" v-for="(item, index) in otherInfo.attachments" :key="index + 'cover'">
                  <img :src="item" alt="" :preview="index" />
                </div>
              </div>
              <a-empty :image="simpleImage" description="暂无评论图片"
                v-if="!otherInfo.attachments || !otherInfo.attachments.length" />
              <div class="text-area">
                <a-textarea :autosize="{ minRows: 6, maxRows: 8 }" :maxLength="500" placeholder="谈谈您对产品的建议,我们会努力改进~"
                  v-model="otherInfo.content" readonly />
                <span class="number-info">{{ otherInfo.content.length }}/500</span>
              </div>
            </div>
          </div>
        </div>
        <template>
          <h2 class="info-title" style="margin-top: 15px">
            <span class="title">我的回复</span>
            <a-button type="link" class="edit-btn" @click="handleEdit"
              v-if="needReplay && otherInfo.replyStatus == '0'"><img
                src="~@/assets/edit.png" /><span>编辑</span></a-button>
          </h2>
          <a-textarea style="width: 100%" :autosize="{ minRows: 6, maxRows: 8 }" :maxLength="500"
            :placeholder="otherInfo.replyStatus == '0' ? '回复' : '请输入回复~'" v-model="otherInfo.replyContext"
            :readonly="otherInfo.replyStatus == '0'" />
          <!-- readonly -->
          <div class="options" v-if="needReplay && otherInfo.replyStatus == '1'">
            <a-button type="primary" style="width: 120px; height: 38px; font-weight: 500" :loading="loading"
              @click="handleReplay">回复评价</a-button>
          </div>
        </template>
      </template>
    </a-tab-pane>
  </a-tabs>
</template>

<script>
import uploadFile from '@/components/plugins/smallUpload.vue'
import { postAction, getAction } from '@/api/manage'
import { mapGetters } from 'vuex'
import { Empty } from 'ant-design-vue'
import Viewer from 'v-viewer';
import 'viewerjs/dist/viewer.css'; 
export default {
  name: 'appraisalInfo',
  props: {
    needReplay: {
      type: Boolean,
      default: true
    }
  },
  components: {
    uploadFile,
    Viewer
  },
  data() {
    return {
      chooseItems: ['1', '3', '4'],
      activeKey: '1',
      tagList: [

      ],
      otherTagList: [],
      visible: false,
      loading: false,
      simpleImage: Empty.PRESENTED_IMAGE_SIMPLE,
      info: {
        anonymousFlag: true,
        qualityStars: 0,
        payStars: 0,
        serviceStars: 0,
        content: '',
        attachments: [],
        replyStatus: '0', // 回复 0 不可， 1 可以
      },
      otherInfo: {
        anonymousFlag: true,
        qualityStars: 0,
        payStars: 0,
        serviceStars: 0,
        content: '',
        attachments: [],
        replyStatus: '0', // 回复 0 不可， 1 可以
      },
      labelCol: {
        span: 8,
      },
      wrapperCol: {
        span: 16,
      },
      desc: ['很不满意', '不满意', '一般', '满意', '非常满意'],
      rateObject: {
        1: '非常差',
        2: '差',
        3: '一般',
        4: '满意',
        5: '非常满意',
      },
      dataSource: [],
      sorter: {
        userType: this.setUserInfo().tradeIdentity,
      },
      columns: [
        {
          title: '产品',
          dataIndex: 'name',
          width: '25%',
          align: 'center',
          scopedSlots: { customRender: 'name' },
        },
        {
          title: '评价',
          dataIndex: 'age',
          width: '75%',
          align: 'center',
          scopedSlots: { customRender: 'age' },
        },
      ],
      url: {
        list: '/order/product',
      },
      disableMixinCreated: true,
      jumpReplay: false,
      hasMine: false,
      hasOther: false
    }
  },
  created() {
    const { orderNo } = this.$route.query
    orderNo && (this.sorter.orderNo = orderNo)
    this.handleLoadData(1)
  },
  methods: {
    ...mapGetters(['setUserInfo']),
    handleLoadData() {
      getAction('/order/detail', {
        ...this.sorter,
      }).then((res) => {
        const { success, data, message } = res
        if (success) {
          const { orderProductsVoList } = data
          if (orderProductsVoList && orderProductsVoList.length) {
            const { productName, productPicture } = orderProductsVoList[0]
            this.info.productName = productName
            this.info.productPicture = productPicture
          }
        } else {
          this.$message.warning(message)
        }
      })
      getAction('/order/evaluation/queryByOrderNo', {
        orderNo: this.sorter.orderNo,
      }).then((res) => {
        const { success, data, message } = res
        if (success) {
          if (data.length) {
            // evaluationType	评价类型 0:采购评价供应商，1 供应商评价采购
            // tradeIdentity == '0' ? '供应商' : '采购商'
            // 提取最新的一个项展示
            let flag = this.setUserInfo().tradeIdentity == '0' ? 1 : 0
            let targetInfo = data.find((item) => item.evaluationType == flag)
            if (targetInfo) {
              this.info = Object.assign(this.info, targetInfo, { attachments: targetInfo.attachments || [], wholeEvaluationStar: targetInfo.wholeEvaluationStar && Number(targetInfo.wholeEvaluationStar) || 0 })
              this.tagList = targetInfo.evaluationTags || []
              if (targetInfo['replyList'] && targetInfo['replyList'].length) {
                this.info.replyContext = targetInfo['replyList'][targetInfo['replyList'].length - 1]['replyContext']
              }
              this.hasMine = true
            } else {
              this.hasMine = false
            }

            // 对方的评价
            let otherFlag = this.setUserInfo().tradeIdentity == '0' ? 0 : 1;
            let otherTargetInfo = data.find((item) => item.evaluationType == otherFlag)
            if (otherTargetInfo) {
              this.otherInfo = Object.assign(this.otherInfo, otherTargetInfo, { attachments: otherTargetInfo.attachments || [], wholeEvaluationStar: otherTargetInfo.wholeEvaluationStar && Number(otherTargetInfo.wholeEvaluationStar) || 0 })
              this.otherTagList = otherTargetInfo.evaluationTags || []
              if (otherTargetInfo['replyList'] && otherTargetInfo['replyList'].length) {
                this.otherInfo.replyContext = otherTargetInfo['replyList'][otherTargetInfo['replyList'].length - 1]['replyContext']
              }
              this.hasOther = true
            } else {
              this.hasOther = false
            }
          }
        } else {
          this.$message.warning(message)
        }
      })
    },
    handleNoName(e) {
      this.info.anonymousFlag = e.target.checked
    },
    /* 回复信息 */
    handleReplay() {
      const { replyContext, id } = this.otherInfo
      if (!replyContext) {
        return this.$message.warning({
          content: (h) => <span style="margin-left: 9px; font-weight: 500;color: #262626;">回复信息不能为空</span>,
          icon: (h) => <img src="./noPass.png" width="21" height="21" />,
        })
      }
      this.loading = true
      postAction('/order/evaluation/reply', {
        businessId: this.setUserInfo().businessId,
        deleteFlag: 0,
        evaluationId: id,
        replyContext,
        replyUserId: this.setUserInfo().userId,
      }).then((res) => {
        const { success, message } = res
        this.loading = false
        if (success) {
          // this.$message.success(message)
          this.handleLoadData()
        } else {
          this.$message.warning(message)
        }
      })
    },
    handleEdit() {
      this.otherInfo.replyStatus = '1';
      this.jumpReplay = true
    },
  },
}
</script>

<style lang="less" scoped>
.content-view {
  width: 100%;
  border-radius: 8px;
  // background-color: #f7f8fa;
  // padding: 25px 25px 10px;

  &.last {
    margin-top: 15px;
    padding: 25px;
  }

  &-header {
    padding-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #efefef;
    margin-bottom: 24px;

    span.date {
      color: rgba(0, 0, 0, 0.85);
      font-size: 16px;
    }

    span.title {
      margin-right: 20px;
      color: #131212;
      font-size: 16px;
      font-weight: 500;
    }

    .ant-checkbox-wrapper {
      color: #131212;
    }
  }

  &-review {
    display: flex;

    .goods-info {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 120px;
      margin-right: 15px;

      .cover-box {
        background-color: #fff;
        width: 120px;
        height: 120px;
        border-radius: 8px;
        overflow: hidden;
        margin-bottom: 8px;
      }

      .goods-desc {
        color: #225588;
        font-size: 12px;
        line-height: 19px;
        white-space: pre-wrap;
        display: -webkit-box;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
      }
    }

    .goods-info-review {
      width: 100%;
    }
  }
}

.star-info {
  display: flex;
  align-items: center;
  line-height: 24px;
  margin-bottom: 15px;

  .label {
    color: #131212;
    margin-right: 20px;
  }
}

.text-area {
  position: relative;
  margin-bottom: 15px;

  .number-info {
    position: absolute;
    right: 8px;
    bottom: 8px;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.45);
  }
}

.options {
  text-align: center;
  margin-top: 30px;

  .ant-btn {
    &+.ant-btn {
      margin-left: 8px;
    }
  }
}

.info-card {
  font-size: 16px;
  color: rgba(0, 0, 0, 0.85);
  display: flex;
  align-items: center;

  img {
    width: 21px;
    height: 21px;
  }

  span {
    margin-left: 10px;
  }
}

.imageArea {
  display: flex;
  flex-wrap: wrap;
  height: 72px;
  grid-gap: 8px 8px;
  margin-bottom: 15px;

  &-item {
    width: 72px;
    height: 100%;
    border-radius: 4px;
    background-color: #fff;
    cursor: pointer;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.info-title {
  font-size: 15px;
  color: #605f5f;
  margin-bottom: 24px;
  height: 38px;
  display: flex;
  align-items: center;
  position: relative;
  border-bottom: 1px solid #EFEFEF;

  &:before {
    background: #ff6e2d;
    display: inline-block;
    content: '';
    width: 5px;
    height: 16px;
    margin-right: 8px;
  }

  .title {
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #000;
    margin-right: 10px;
  }

  .subTitle {
    font-weight: 400;
    font-size: 14px;
    color: #8d8e99;
  }
}

.edit-btn {
  position: absolute;
  right: 0;

  img {
    width: 14px;
    margin-right: 5px;
  }
}


.tagList {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 18px 18px;
  margin-bottom: 14px;

  &-item {
    padding: 4px 8px 4px 8px;
    height: 30px;
    border: 1px solid #D9D9D9;
    border-radius: 4px;
    color: #262626;
    line-height: 20px;
    cursor: pointer;
    font-size: 14px;
    background-color: #fff;

    &.active {
      border-color: #FF6E2D;
      color: #FF6E2D;
      background-color: #FFF2EA;
    }
  }
}

::v-deep .ant-tabs-left-bar {
  .ant-tabs-tab {
    margin-right: 15px;
  }
}

.newEmpty {
  margin: 120px auto;
  ::v-deep .ant-empty-image {
    height: 120px!important;
  }
  ::v-deep .ant-empty-image img {
    height: 120px!important;
  }
  ::v-deep .ant-empty-description {
    color: #999;
  }
}
.backEnd .ant-tabs-bar {
  border-bottom: 1px solid #efefef!important;
}
</style>
