<!--
 * @Description: 服务商单据
 * @Author: zhang zhen
 * @Date: 2023-02-15 17:34:00
 * @LastEditors: zhang zhen
 * @LastEditTime: 2024-02-03 18:54:41
 * @FilePath: /page-sass/src/views/requirement/orderManagement/modules/children/facilitatorBills.vue
-->
<template>
  <div class="facilitatorBills">
    <div class="facilitatorBills-left">
      <EmptyArea v-if="!fileList.length" style="width: 400px;height:200px" />
      <template v-else>
        <img
          :src="(fileList[0] && fileList[0]['url']) || require('../../../../../assets/300x277.png')"
          class="cover"
          alt=""
          width="300px"
        />
        <a-button type="danger" ghost style="width: 105px;margin-left: 15px;" @click="handleDownload">
          下载
        </a-button>
      </template>
    </div>
    <a-upload
      v-if="orderStatus <= 1"
      name="file"
      :action="uploadAction"
      :headers="headers"
      :data="{ dir: 'images' }"
      :fileList="fileList"
      :disabled="fileList.length >= 1"
      @change="handleChange"
    >
      <a-button type="danger" ghost>
        买方确认付款
      </a-button>
    </a-upload>
  </div>
</template>

<script>
import { postAction, getAction } from '@/api/manage'
import EmptyArea from '@/components/plugins/EmptyArea.vue'
const uidGenerator = () => {
  return '-' + parseInt(Math.random() * 10000 + 1, 10)
}
export default {
  name: 'facilitatorBills',
  components: {
    EmptyArea
  },
  props: {
    orderNo: {
      type: String,
      default: ''
    },
    orderStatus: {
      type: [String, Number],
      default: '1'
    }
  },
  data() {
    return {
      uploadAction: `${process.env.VUE_APP_API_BASE_URL}/file/images/upload`,
      headers: {},
      fileList: [],
      newOrderNo: null,
    }
  },
  created() {
    this.headers = { token: JSON.parse(localStorage.getItem('authInfo'))['Authorization'] }
  },
  methods: {
    handleLoadPayInfo(orderNo) {
      this.newOrderNo = orderNo;
      getAction('/order/pay/get', {
        orderNo: orderNo || this.newOrderNo
      }).then(res => {
        const { success, data } = res
        if (success) {
          const { payFile } = data
          this.fileList = [
            {
              url: payFile,
              srcFileName: '付款单',
              type: 'png',
              uid: uidGenerator(),
              status: 'done',
              response: {}
            }
          ]
        }
      })
    },
    handleChange(info) {
      let fileList = info.fileList
      console.warn(info.file)
      if (info.file.status === 'done') {
        fileList = fileList.splice(-1, 1)

        if (info.file.response.success) {
          fileList = fileList.map(res => {
            if (res.response && res.response.data) {
              const { url, filename, ext } = res.response.data
              res.url = url
              res.srcFileName = filename
              res.fileName = filename
              res.fileType = ext
            }
            return res
          })
          // console.log(fileList)
          // this.$message.success(`${info.file.name} 上传成功!`)

          postAction('/order/pay', {
            orderNo: this.orderNo || this.newOrderNo,
            file: fileList[0]['url']
          }).then(res => {
            const { success, message, data } = res
            if (success) {
              // this.$message.success(message)
              postAction('/order/payed', {
                orderNo: this.orderNo || this.newOrderNo
              }).then(res => {
                const { success, message, data } = res
                if (success) {
                  // this.$message.success(message)
                  this.handleLoadInfo()
                } else {
                  this.$message.warning(message)
                }
              })
            } else {
              this.$message.warning(message)
            }
          })
        } else {
          this.$message.error(
            `${info.file.response.success ? info.file.response.message : '文件上传失败，不支持当前格式'}!`
          )
          this.fileList = []
        }
      } else if (info.file.status === 'error') {
        this.$message.error(`${info.file.name} 上传失败.`)
        this.fileList = []
      }
      this.fileList = fileList
    },
    handleDownload() {
      window.open(this.fileList[0]['url'])
    }
  }
}
</script>

<style lang="less" scoped>
.facilitatorBills {
  .flexLayout(@alignItem: flex-start);
  padding-top: 30px;
  &-left {
    .cover {
      width: 300px;
    }
    .flexLayout(@alignItem: flex-end);
  }
}
</style>
