<!--
 * @Description: 上传进度
 * @Author: zhang zhen
 * @Date: 2023-02-15 17:53:27
 * @LastEditors: zhang zhen
 * @LastEditTime: 2023-05-03 21:07:32
 * @FilePath: /page-sass/src/views/requirement/orderManagement/modules/children/UploadProgress.vue
-->
<template>
  <div class="UploadProgress">
    <div class="UploadProgress-left">
      <img src="~@/assets/300x277.png" class="cover" alt="" />
      <div class="cover-item">
        <div class="cover-item-left">
          <span class="name">方案名称</span>
          <span class="date">2023-02-16</span>
        </div>
        <a-button type="danger" ghost @click="handleSwitchNav">
          填写详细方案参数
        </a-button>
      </div>
    </div>
    <a-button type="danger" ghost>
      买方确认付款
    </a-button>
  </div>
</template>

<script>
export default {
  name: 'UploadProgress',
  props: {
    orderNo: {
      type: String,
      default: ''
    }
  },
  methods: {
    handleSwitchNav(item) {
        this.$router.push('/requirement/DetailedParameter')
    }
  },
}
</script>

<style lang="less" scoped>
.UploadProgress {
  .flexLayout(@alignItem: flex-start);
  padding-top: 30px;
  &-left {
    .cover {
      width: 208px;
      &-item {
        margin-top: 15px;
        .flexLayout(@alignItem: flex-end);
        &-left {
          .flexLayout(@alignItem: flex-start);
          flex-direction: column;
          margin-right: 8px;
          .name {
            font-weight: bold;
            color: #333;
          }
          .date {
            color: #797979;
          }
        }
      }
    }
    .flexLayout(@alignItem: flex-start);
    flex-direction: column;
  }
}
</style>
