<!--
 * @Description: 物流表单
 * @Author: zhang zhen
 * @Date: 2023-05-03 14:52:31
 * @LastEditors: zhang zhen
 * @LastEditTime: 2023-05-22 21:01:08
 * @FilePath: /page-sass/src/views/requirement/orderManagement/modules/children/transportsForm.vue
-->
<template>
  <a-modal
    title="物流运输单编辑"
    :visible="visible"
    destroyOnClose
    :confirm-loading="confirmLoading"
    @ok="handleOk"
    @cancel="handleCancel"
  >
    <a-form-model
      v-bind="{
        labelCol: { span: 9 },
        wrapperCol: { span: 15 }
      }"
      ref="infoForm"
      :model="form"
      :rules="rules"
    >
      <a-form-model-item label="收货人姓名:" labelAlign="left" required>
        <a-input placeholder="自动带入" v-model="form.deliveryName"></a-input>
      </a-form-model-item>
      <a-form-model-item label="收货人手机号:" labelAlign="left" required>
        <a-input placeholder="自动带入" v-model="form.phone"></a-input>
      </a-form-model-item>
      <a-form-model-item label="收货地址:" labelAlign="left" prop="deliveryAddress">
        <!-- <a-cascader
          :field-names="{ label: 'regionName', value: 'deliveryAddress', children: 'children' }"
          :options="regionOptions"
          :load-data="loadData"
          placeholder="请选择收货地址"
          v-model="form.deliveryAddress"
          allowClear
          change-on-select
        /> -->
        <a-row :gutter="6">
          <a-col :span="8">
            <a-select placeholder="省" v-model="form.deliveryAddress" @change="changeInfo">
              <a-select-option :value="i.regionId" v-for="i in regionList" :key="i.regionId">{{
                i.regionName
              }}</a-select-option>
            </a-select>
          </a-col>
          <a-col :span="8">
            <a-select
              placeholder="市"
              v-model="form.deliveryCity"
              @change="handleLoadCity('deliveryDistrict')"
            >
              <a-select-option :value="i.regionId" v-for="i in deliveryCityList" :key="i.regionId">{{
                i.regionName
              }}</a-select-option>
            </a-select>
          </a-col>
          <a-col :span="8">
            <a-select placeholder="区" v-model="form.deliveryDistrict" @change="handleUpdateView">
              <a-select-option :value="i.regionId" v-for="i in deliveryDistrictList" :key="i.regionId">{{
                i.regionName
              }}</a-select-option>
            </a-select>
          </a-col>
        </a-row>
      </a-form-model-item>
      <a-form-model-item label="详细地址:" labelAlign="left" prop="detailAddress">
        <a-input placeholder="详细地址" v-model="form.detailAddress"></a-input>
      </a-form-model-item>
      <a-form-model-item label="送货方式:" labelAlign="left" prop="transportMode">
        <a-select v-model="form.transportMode" style="width: 120px" placeholder="请选择送货方式">
          <!-- 0-普通快递，1-物流公司，2-自提，3-专车送货，4-同城配送，5-邮政 -->
          <a-select-option :value="0">
            普通快递
          </a-select-option>
          <a-select-option :value="1">
            物流公司
          </a-select-option>
          <a-select-option :value="2">
            自提
          </a-select-option>
          <a-select-option :value="3">
            专车送货
          </a-select-option>
          <a-select-option :value="4">
            同城配送
          </a-select-option>
          <a-select-option :value="5">
            邮政
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="运单号:" labelAlign="left" prop="waybill">
        <a-input placeholder="运单号" v-model="form.waybill"></a-input>
      </a-form-model-item>
      <a-form-model-item label="承运人:" labelAlign="left" prop="shippingUser">
        <a-input placeholder="承运人" v-model="form.shippingUser"></a-input>
      </a-form-model-item>
      <a-form-model-item label="备注:" labelAlign="left">
        <a-textarea
          placeholder="请输入备注"
          allow-clear
          v-model="form.description"
          :autosize="{ minRows: 6, maxRows: 6 }"
        />
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>
import { getAction, postAction } from '@/api/manage'
export default {
  name: 'transportsForm',
  data() {
    return {
      visible: false,
      confirmLoading: false,
      form: {},
      rules: {
        deliveryAddress: { required: true, message: '请选择收货地址' },
        detailAddress: { required: true, message: '请选择详细地址' },
        transportMode: { required: true, message: '请选择送货方式' },
        waybill: { required: true, message: '请输入运单号' },
        shippingUser: { required: true, message: '请输入承运人' }
      },
      regionOptions: [],
      regionList: [],
      deliveryCityList: [],
      deliveryDistrictList: []
    }
  },
  methods: {
    handleEdit(info) {
      this.loadRegionList()
      this.visible = true
      this.form = Object.assign({}, info)
      const { deliveryAddress, deliveryCity } = info
      deliveryAddress && this.handleChangeRootId(true)
      deliveryCity && this.handleLoadCity('deliveryDistrict', true)
    },
    changeInfo(e) {
      this.form.deliveryAddress = e
      this.handleUpdateView()
      this.handleChangeRootId()
    },
    handleOk() {
      this.$refs['infoForm'].validate(valid => {
        if (valid) {
          const { deliveryAddress } = this.form
          this.confirmLoading = true
          postAction('/order/transport', {
            ...this.form,
          }).then(res => {
            const { success, message } = res
            this.confirmLoading = false
            if (success) {
              // this.$message.success(message)
              this.$emit('ok')
              this.handleCancel()
            } else {
              this.$message.warning(message)
            }
          })
        }
      })
    },
    loadRegionList() {
      getAction('/area/queryByLevel', {
        level: 1
      }).then(res => {
        const { success, data } = res
        if (success) {
          this.regionList = data
        }
      })
    },
    /* 获取用户信息更新 */
    handleLoadUserInfo(callback) {
      let storageInfo = localStorage.getItem('authInfo')
      const { userId } = JSON.parse(storageInfo)
      getAction('/user/info', { userId }).then(res => {
        const { success, data } = res
        if (success) {
          let userInfo = JSON.stringify(data)
          localStorage.setItem('userInfo', userInfo)
          this.$store.commit('setUserInfo', data)
          callback()
        }
      })
    },
    handleChangeRootId(flag = false) {
      this.deliveryCityList = []
      if (!flag) {
        this.deliveryDistrictList = []
        this.form.deliveryCity = undefined
        this.form.deliveryDistrict = undefined
      }
      this.handleLoadCity('deliveryCity', flag)
    },
    handleUpdateView() {
      this.$forceUpdate()
    },
    handleLoadCity(key, flag = false) {
      let parentId = ''
      if (key == 'deliveryCity') {
        this.deliveryCityList = []
        this.deliveryDistrictList = []
        !flag && (this.form.deliveryCity = undefined)
        parentId = this.form.deliveryAddress
      }
      if (key == 'deliveryDistrict') {
        this.deliveryDistrictList = []
        !flag && (this.form.deliveryDistrict = undefined)
        parentId = this.form.deliveryCity
      }
      getAction('/area/queryByParentIdList', {
        parentId
      }).then(res => {
        const { success, data } = res
        if (success) {
          this[`${key}List`] = data
        }
      })
    },
    handleCancel() {
      this.form = {}
      this.visible = false
      this.confirmLoading = false
    },
    // loadRegionList() {
    //   getAction('/area/queryByLevel', {
    //     level: 1
    //   }).then(res => {
    //     const { success, data } = res
    //     if (success) {
    //       this.regionOptions = data.map(item => ({ ...item, isLeaf: false }))
    //     }
    //   })
    // },
    handleInitData(targetOption) {
      const { deliveryAddress, regionName } = targetOption
      targetOption.loading = true
      getAction('/area/queryByParentIdList', {
        parentId: deliveryAddress
      }).then(res => {
        const { success, data } = res
        if (success) {
          targetOption.loading = false
          targetOption.children = data.map(item => ({ ...item, isLeaf: false }))
          this.$forceUpdate()
        }
      })
    },
    loadData(selectedOptions) {
      const targetOption = selectedOptions[selectedOptions.length - 1]
      // load options lazily
      this.handleInitData(targetOption)
    }
  }
}
</script>

<style lang="less" scoped></style>
