<!--
 * @Description: 详情展示的数据
 * @Author: zhang zhen
 * @Date: 2024-09-29 11:26:52
 * @LastEditors: zhang zhen
 * @LastEditTime: 2024-09-29 11:29:41
 * @FilePath: /page-sass/src/views/requirement/orderManagement/modules/children/fileListViewNew.vue
-->
<template>
  <div class="view-file-box">
    <div class="view-file-box-item" v-for="(i, index) in fileList" :key="i.id">
      <!-- 标题 -->
      <div class="file-viewer-title" v-show="index == 0">
        {{ i.filePartName || '' }}
      </div>
      <!-- ext -->
      <img v-if="i.ext == 'doc' || i.ext == 'docx'" src="~@/assets/fileIcon/word.png" alt="" class="file-icon" />
      <img src="~@/assets/fileIcon/excel.png" v-else-if="i.ext == 'xlsx' || i.ext == 'xls'" alt="" class="file-icon" />
      <img src="~@/assets/fileIcon/jpg.png" v-else-if="i.ext == 'jpg'" alt="" class="file-icon" />
      <img src="~@/assets/fileIcon/pdf.png" v-else-if="i.ext == 'pdf'" alt="" class="file-icon" />
      <img src="~@/assets/fileIcon/png.png" v-else-if="i.ext == 'png'" alt="" class="file-icon" />
      <img src="~@/assets/fileIcon/txt.png" v-else-if="i.ext == 'txt'" alt="" class="file-icon" />
      <img src="~@/assets/fileIcon/zip.png" v-else-if="i.ext == 'zip' || i.ext == 'rar'" alt="" class="file-icon" />
      <img src="~@/assets/fileIcon/default.png" v-else alt="" class="file-icon" />
      <div class="file-info">
        <!-- filename -->
        <span class="file-name">{{ i.filename || '-' }}</span>
        <!-- size 字节 -->
        <!-- {{ ((i.size || 0) / 1024).toFixed(2) }}  kb-->
        <span class="file-size">文件大小：{{ ((i.size || 0) / 1024).toFixed(2) }}kb</span>

        <!-- 操作区域 -->
        <div class="option-area">
          <a-button type="link" @click="handleDownload(i.url)">下载</a-button>
          <!-- <a-button type="link">删除</a-button> -->
        </div>
      </div>
    </div>
    <EmptyArea v-if="!fileList.length" style="width: 100%" :description="desc" />
  </div>
</template>

<script>
import EmptyArea from '@/components/plugins/EmptyArea.vue'
import { postAction } from '@/api/manage.js'
function downloadImage(url) {
  let name = url.split('/images/')[1]

  if (url.includes('/file/pdf/')) {
    name = url.split('/file/pdf/')[1]
  }
  fetch(url)
    .then((response) => response.blob())
    .then((blob) => {
      const url = window.URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.style.display = 'none'
      a.href = url
      a.download = name
      document.body.appendChild(a)
      a.click()
      window.URL.revokeObjectURL(url)
    })
    .catch(() => alert('Failed to download image.'))
}
export default {
  components: {
    EmptyArea,
  },
  props: {
    desc: {
      type: String,
      default: '暂无数据'
    },
  },
  data() {
    return {
      fileList: [],
    }
  },
  methods: {
    handleGetFileList(fileList = [], fileObject = {}) {
      postAction('/file/queryFileInfoList', fileList).then((res) => {
        const { success, data } = res
        if (success) {
          this.fileList = []
          if (data && data.length) {
            this.fileList = data.map((i, index) => ({ ...i, filePartName: fileObject[i.url] }))
          }
        }
      })
    },
    handleDownload(url) {
      downloadImage(url)
    },
  },
}
</script>

<style lang="less" scoped>
.view-file-box {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  grid-gap: 25px 25px;

  &-item {
    margin-top: 50px;
    min-width: 346px;
    height: 78px;
    border: 1px dotted #e8e9eb;
    display: flex;
    padding: 15px;
    box-sizing: border-box;
    justify-content: flex-start;
    align-items: center;
    background: #f7f8fa;
    border-radius: 4px;
    position: relative;

    .file-icon {
      width: 40px;
      height: 40px;
      margin-right: 8px;
    }

    .file-info {
      flex: 1 0 0;
      height: 46px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;

      .file-name {
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: 0px;
        text-align: left;
        color: #131212;
      }

      .file-size {
        font-size: 12px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0px;
        text-align: left;
        color: #8d8e99;
      }
    }

    .option-area {
      position: absolute;
      right: 8px;
      bottom: 8px;
    }
  }
}

.file-viewer-title {
  font-family: PingFang SC;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
  color: #000;
  position: absolute;
  left: 0;
  top: -40px;
}
</style>
