<!--
 * @Description: 采购商单据上传
 * @Author: zhang zhen
 * @Date: 2023-05-20 22:13:01
 * @LastEditors: zhang zhen
 * @LastEditTime: 2024-09-20 22:45:46
 * @FilePath: /page-sass/src/views/requirement/orderManagement/modules/children/billUploader.vue
-->
<template>
  <div>
    <FileListView ref="FileListViewRef" />
  </div>
</template>

<script>
import EmptyArea from '@/components/plugins/EmptyArea.vue'
import { postAction, postQueryAction } from '@/api/manage'
import FileListView from './fileListView.vue'

const uidGenerator = () => {
  return '-' + parseInt(Math.random() * 10000 + 1, 10)
}
export default {
  name: 'BillUploader',
  components: {
    EmptyArea,
    FileListView,
  },
  props: {
    orderNo: {
      type: String,
      default: '',
    },
    orderStatus: {
      type: [String, Number],
      default: '1',
    },
  },
  data() {
    return {
      info: {
        invoiceFile: null, // 发货单
        invoiceDate: null,
        signFile: null, // 收货单
        signDate: null,
      },
      payTime: false,
      payFile: null,
      preTime: null,
      fapiaoFile: null,
      newOrderNo: null,
    }
  },
  methods: {
    handleLoadTransportInfo(orderNo) {
      this.newOrderNo = orderNo
      postQueryAction('/order/payment/record', {
        orderNo: this.orderNo || orderNo,
      }).then((res) => {
        const { success, message, data } = res
        if (success && data && data.length) {
          let fileObject = {},
            fileList = [];
          for (const element of data) {
            const { paymentVoucher, paymentType } = element
            console.log(paymentType, paymentType == 1 ? '预付款支付单据' : '尾款支付单据')
            fileObject[paymentVoucher] = paymentType == 1 ? '预付款支付单据' : '尾款支付单据'
            fileList.push(paymentVoucher)
          }
          this.$nextTick((_) => {
            this.$refs.FileListViewRef.handleGetFileList(fileList, fileObject)
          })
        }
      })
    },
    handleDownload(url) {
      if (!url) return this.$message.warning('没有单据信息')
      window.open(url)
    },
  },
}
</script>

<style lang="less" scoped>
.UploadProgress {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  grid-gap: 24px 24px;

  &-item {
    width: 334px;
    height: 208px;
    background: #f7f8fa;
    border-radius: 8px;
    padding: 24px;
    display: flex;
    align-items: flex-start;

    img.cover {
      width: 200px;
      height: 160px;
      border-radius: 8px;
      margin-right: 14px;
    }

    .infos {
      .title {
        font-weight: 500;
        font-size: 16px;
        color: #222;
      }

      .time {
        color: #595959;
        font-size: 12px;
        margin-top: 4px;
      }

      .option {
        color: #ff6e2d;
        font-size: 12px;
        display: flex;
        align-items: center;
        cursor: pointer;

        span {
          margin-left: 5px;
        }
      }
    }
  }
}
</style>
