<!--
 * @Description: 修改收货地址
 * @Author: zhang zhen
 * @Date: 2023-05-30 11:00:59
 * @LastEditors: zhang zhen
 * @LastEditTime: 2024-05-31 17:05:10
 * @FilePath: /page-sass/src/views/requirement/orderManagement/modules/children/changeShipping.vue
-->
<template>
  <DrawerView :visible="visible" :width="560" title="修改收货地址" @confirm="handleOk" @cancel="handleCancel">
    <a-form-model
      class="info-form"
      :model="form"
      :label-col="{
        span: 5
      }"
      :wrapper-col="{
        span: 18
      }"
      ref="infoForm"
      :colon="false"
      :rules="rules"
    >
      <a-form-model-item label="收货人" required>
        <a-input placeholder="请输入" v-model="form.deliveryName" style="width: 380px"></a-input>
      </a-form-model-item>
      <a-form-model-item label="手机号" required>
        <a-input placeholder="请输入" v-model="form.phone" style="width: 380px"></a-input>
      </a-form-model-item>
      <a-form-model-item label="期望收货时间" prop="expectDate">
        <j-date placeholder="请选择" v-model="form.expectDate" style="width: 380px" :removePast="true" />
      </a-form-model-item>
      <a-form-model-item label="收货地址" prop="deliveryAddress">
        <a-row :gutter="6" style="width: 384px">
          <a-col :span="8">
            <a-select placeholder="省" v-model="form.deliveryAddress" @change="changeInfo">
              <a-icon type="caret-down" slot="suffixIcon" style="color: #8C8C8C" />
              <a-select-option :value="i.regionId" v-for="i in regionList" :key="i.regionId">{{
                i.regionName
              }}</a-select-option>
            </a-select>
          </a-col>
          <a-col :span="8">
            <a-select placeholder="市" v-model="form.deliveryCity" @change="handleLoadCity('deliveryDistrict')">
              <a-icon type="caret-down" slot="suffixIcon" style="color: #8C8C8C" />
              <a-select-option :value="i.regionId" v-for="i in deliveryCityList" :key="i.regionId">{{
                i.regionName
              }}</a-select-option>
            </a-select>
          </a-col>
          <a-col :span="8">
            <a-select placeholder="区" v-model="form.deliveryDistrict" @change="handleUpdateView">
              <a-icon type="caret-down" slot="suffixIcon" style="color: #8C8C8C" />
              <a-select-option :value="i.regionId" v-for="i in deliveryDistrictList" :key="i.regionId">{{
                i.regionName
              }}</a-select-option>
            </a-select>
          </a-col>
        </a-row>
      </a-form-model-item>
      <a-form-model-item label="详细地址" prop="detailAddress">
        <a-input placeholder="请输入" v-model.trim="form.detailAddress" style="width: 380px" :maxLength="20">
          <div class="NumberInfo" slot="suffix">{{ form.detailAddress && form.detailAddress.length || 0 }}/20</div>
        </a-input>
      </a-form-model-item>
    </a-form-model>
    <template v-slot:btnArea>
      <a-button @click="handleCancel" style="color: rgba(0, 0, 0, 0.65);">取消</a-button>
      <a-button @click="handleOk" type="primary" :loading="confirmLoading">确认</a-button>
    </template>
  </DrawerView>
</template>

<script>
import { getAction, postAction } from '@/api/manage'
import DrawerView from '@/components/plugins/drawerView.vue'
import UploadDrag from '@/components/plugins/uploadDrag'
import JDate from '@/components/easyComponents/JDate.vue'
import JDictSelect from '@/components/plugins/JDictSelect.vue'
export default {
  name: 'transportsForm',
  components: {
    UploadDrag,
    JDate,
    DrawerView,
    JDictSelect
  },
  data() {
    return {
      visible: false,
      confirmLoading: false,
      form: {
        detailAddress: ''
      },
      rules: {
        deliveryAddress: { required: true, message: '请选择收货地址' },
        detailAddress: [
          { required: true, message: '请输入详细地址' },
          { min: 4, max: 20, message: '详细地址最少4个字, 最多20个字' },
        ],
        expectDate: { required: true, message: '请选择收货时间' },
      },
      regionOptions: [],
      regionList: [],
      deliveryCityList: [],
      deliveryDistrictList: [],
      uploadFiles: [],
      lastDate: null,
      orderNo: null,
    }
  },
  methods: {
    handleEdit(item) {
      this.loadRegionList()
      this.visible = true
      this.form = { ...item }
      if (item.deliveryAddress) {
        getAction('/area/queryByParentIdList', {
          parentId: item.deliveryAddress
        }).then(res => {
          const { success, data } = res
          if (success) {
            this.deliveryCityList = data
          }
        })
      }
      if (item.deliveryCity) {
        getAction('/area/queryByParentIdList', {
        parentId: item.deliveryCity
      }).then(res => {
        const { success, data } = res
        if (success) {
          this.deliveryDistrictList = data
        }
      })
      }
    },
    handleChangeFile(e) {
      this.uploadFiles = e.splice(-1, 1)
    },
    changeInfo(e) {
      this.form.deliveryAddress = e
      this.handleUpdateView()
      this.handleChangeRootId()
    },
    handleOk() {
      this.$refs['infoForm'].validate(valid => {
        if (valid) {
          this.confirmLoading = true
          postAction('/order/transport/updateTransport', {
            ...this.form,
          }).then(res => {
            const { success, message } = res
            this.confirmLoading = false
            if (success) {
              this.handleCancel()
              this.$emit('ok')
            } else {
              this.$message.warning(message)
            }
          }).catch(e => {
            this.confirmLoading = false
          })
        }
      })
    },
    loadRegionList() {
      getAction('/area/queryByLevel', {
        level: 1
      }).then(res => {
        const { success, data } = res
        if (success) {
          this.regionList = data
        }
      })
    },
    /* 获取用户信息更新 */
    handleLoadUserInfo(callback) {
      let storageInfo = localStorage.getItem('authInfo')
      const { userId } = JSON.parse(storageInfo)
      getAction('/user/info', { userId }).then(res => {
        const { success, data } = res
        if (success) {
          let userInfo = JSON.stringify(data)
          localStorage.setItem('userInfo', userInfo)
          this.$store.commit('setUserInfo', data)
          callback()
        }
      })
    },
    handleChangeRootId(flag = false) {
      this.deliveryCityList = []
      if (!flag) {
        this.deliveryDistrictList = []
        this.form.deliveryCity = undefined
        this.form.deliveryDistrict = undefined
      }
      this.handleLoadCity('deliveryCity', flag)
    },
    handleUpdateView() {
      this.$forceUpdate()
    },
    handleLoadCity(key, flag = false) {
      let parentId = ''
      if (key == 'deliveryCity') {
        this.deliveryCityList = []
        this.deliveryDistrictList = []
        !flag && (this.form.deliveryCity = undefined)
        parentId = this.form.deliveryAddress
      }
      if (key == 'deliveryDistrict') {
        this.deliveryDistrictList = []
        !flag && (this.form.deliveryDistrict = undefined)
        parentId = this.form.deliveryCity
      }
      getAction('/area/queryByParentIdList', {
        parentId
      }).then(res => {
        const { success, data } = res
        if (success) {
          this[`${key}List`] = data
        }
      })
    },
    handleCancel() {
      this.form = {
        detailAddress: ''
      }
      this.lastDate = null
      this.visible = false
      this.confirmLoading = false
      this.uploadFiles = []
    },

    handleInitData(targetOption) {
      const { deliveryAddress, regionName } = targetOption
      targetOption.loading = true
      getAction('/area/queryByParentIdList', {
        parentId: deliveryAddress
      }).then(res => {
        const { success, data } = res
        if (success) {
          targetOption.loading = false
          targetOption.children = data.map(item => ({ ...item, isLeaf: false }))
          this.$forceUpdate()
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
@import '~@/styles/form.less';

.uploadCover {
  width: 560px;
  min-height: 244px;
  background: #f9f9f9;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .endTitle {
    margin-top: -15px;
    .flexLayout();
    font-size: 12px;
    justify-content: center;
    color: #8d8e99;
    margin-top: 8px;
  }
}
.NumberInfo {
  color: rgba(0, 0, 0, 0.45);
}
.ant-form-item-label > label {
  color: #000;
}

.info-form {
  ::v-deep .ant-form-item-label {
    width: 102px!important;
  }
}
</style>
