import { render, staticRenderFns } from "./supplierInfoForUser.vue?vue&type=template&id=5e83afa4&scoped=true"
import script from "./supplierInfoForUser.vue?vue&type=script&lang=js"
export * from "./supplierInfoForUser.vue?vue&type=script&lang=js"
import style0 from "./supplierInfoForUser.vue?vue&type=style&index=0&id=5e83afa4&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5e83afa4",
  null
  
)

export default component.exports