<!--
 * @Description: 上传单据
 * @Author: zhang zhen
 * @Date: 2023-02-15 17:05:08
 * @LastEditors: zhang zhen
 * @LastEditTime: 2023-02-15 17:19:12
 * @FilePath: /page-sass/src/views/requirement/orderManagement/modules/children/uploadBills.vue
-->
<template>
  <a-card title="拖拽上传" class="infoCard">
    <a-button slot="extra" type="danger" ghost>确认付款</a-button>
    <a-upload-dragger name="file" :multiple="true">
      <p class="ant-upload-drag-icon">
        <a-icon type="cloud-upload" />
      </p>
      <p class="ant-upload-text">
        将文件拖拽到此处
      </p>
      <p class="ant-upload-hint">
        或点击上传
      </p>
    </a-upload-dragger>
  </a-card>
</template>

<script>
export default {
  name: 'UploadBills'
}
</script>

<style lang="less" scoped>
.infoCard {
  border-radius: 8px;
  border: none;
  ::v-deep .ant-upload {
    width: 380px;
  }
}
</style>
