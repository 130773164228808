import { render, staticRenderFns } from "./contractPart.vue?vue&type=template&id=0c2d4a56&scoped=true"
import script from "./contractPart.vue?vue&type=script&lang=js"
export * from "./contractPart.vue?vue&type=script&lang=js"
import style0 from "./contractPart.vue?vue&type=style&index=0&id=0c2d4a56&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0c2d4a56",
  null
  
)

export default component.exports