<!--
 * @Description: 小尺寸上传组件
 * @Author: zhang zhen
 * @Date: 2023-08-06 17:17:30
 * @LastEditors: zhang zhen
 * @LastEditTime: 2024-07-17 11:01:56
 * @FilePath: /page-sass/src/components/plugins/newUpload.vue
-->
<template>
    <!-- 文件上传 -->
    <div>
      <!-- <div ref="uploader"> -->
        <a-upload
          accept="image/png, image/jpeg, image/jpg, image/bmp, application/pdf"
          :listType="uploadType"
          class="avatar-uploader"
          :disabled="disabled"
          :multiple="true"
          :action="uploadAction"
          :headers="headers"
          :data="{ dir: 'images' }"
          :fileList="fileList"
          :remove="removeOption"
          @change="handleChangeImage"
          @preview="handlePreview"
        >
          <!-- :beforeUpload="beforeUpload" -->
          <!-- <a-button v-if="uploadType == 'text'" type="primary" icon="upload" :disabled="fileList.length >= number">
            上传
          </a-button> -->
          <div class="img-box" v-if="fileList.length < number">
            <img src="~@/assets/largeUpload.png" alt="" class="upload-img" />
          </div>
        </a-upload>
      <!-- </div> -->
  
      <div class="memo">
        <a v-if="fileList.length" @click="handleReUpload">重新上传</a>
        <span class="desc">{{ memo }}</span>
      </div>
      <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel" :destroyOnClose="true">
        <img v-if="imageName.IsPicture()" :src="imageUrl" style="width: 100%" preview="1" />
        <video v-if="uploadFileType == 'video'" style="width: 100%" controls="controls" autoplay :src="imageUrl"></video>
        <audio v-if="uploadFileType == 'audio'" :src="imageUrl" style="width: 100%" controls="controls" autoplay />
        <a :href="imageUrl" target="_target">{{ imageName }}</a>
      </a-modal>
    </div>
  </template>
  <script>
  import Vue from 'vue'
  // String 构造函数的原型对象的一个方法。
  String.prototype.IsPicture = function() {
    //判断是否是图片 - strFilter必须是小写列举
    var strFilter = '.jpeg|.jpg|.png|.pic|'
    if (this.indexOf('.') > -1) {
      var p = this.lastIndexOf('.')
      //alert(p);
      //alert(this.length);
      var strPostfix = this.substring(p, this.length) + '|'
      strPostfix = strPostfix.toLowerCase()
      //alert(strPostfix);
      if (strFilter.indexOf(strPostfix) > -1) {
        //alert("True");
        return true
      }
    }
    //alert('False');
    return false
  }
  const uidGenerator = () => {
    return '-' + parseInt(Math.random() * 10000 + 1, 10)
  }
  export default {
    name: 'SuploadFile',
    props: {
      // 上传的其他信息
      defaultParams: {
        type: Object,
        default: function() {
          return {}
        }
      },
      // 上传类型
      uploadFileType: {
        type: String,
        required: false,
        default: 'image'
      },
      // 这个属性用于控制文件上传的业务路径
      bizPath: {
        type: String,
        required: false,
        default: 'temp'
      },
      memo: {
        type: String,
        default: '支持格式：PDF、JPEG等'
      },
      // 文件显示效果
      uploadType: {
        type: String,
        default: 'picture-card'
      },
      // 文件地址
      value: {
        type: [Array],
        required: false
      },
      number: {
        type: Number,
        required: false,
        default: 0
      },
      disabled: {
        type: Boolean,
        require: false,
        default: false
      },
      needMemo: {
        type: Boolean,
        require: false,
        default: false
      }
    },
    data() {
      return {
        uploadAction: `${process.env.VUE_APP_API_BASE_URL}/file/images/upload`,
        headers: {},
        uploadGoOn: true, // 上传错误的不展示
        cheackOptions: {
          image: ['image/jpg', 'image/jpeg', 'image/png'],
          video: ['video/mp4'],
          audio: ['audio/wav'],
          document: [
            'application/wps-writer',
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            'application/pdf', // pdf
            'application/vnd.ms-excel', // xls
            'xlsx', // xlsx
            'application/msword', // doc
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // docx
            'application/vnd.ms-powerpoint', // ppt
            'application/vnd.openxmlformats-officedocument.presentationml.presentation' // pptx
          ]
        },
        fileList: [],
        previewVisible: false,
        imageUrl: '', // 查看当前文件的url
        imageName: '', // 查看当前文件的name
        newParams: {}
      }
    },
    created() {
      this.headers = { token: JSON.parse(localStorage.getItem('authInfo'))['Authorization'] }
    },
    watch: {
      value: {
        handler(newVal, oldVal) {
          // console.log(newVal, oldVal)
          this.initFileList(newVal)
        },
        immediate: true
      }
    },
    methods: {
      handleReUpload() {
        // console.log(this.$refs.uploader)
        this.fileList = []
        const div = document.querySelector('.ant-upload-select-picture-card');
        const input = div.querySelector('input[type="file"]');
        input.click()
        this.$emit('change', null)
      },
      initFileList(list) {
        // console.log(list)
        if (!list || list.length == 0) {
          this.fileList = []
          return
        }
        let fileList = []
        for (var a = 0; a < list.length; a++) {
          const { url, srcFileName } = list[a]
          console.log(list[a])
          fileList.push({
            fileName: srcFileName,
            srcFileName,
            name: srcFileName,
            requestUrl: url,
            url: url,
            type: srcFileName.split('.')[1],
            uid: uidGenerator(),
            status: 'done',
            response: {}
          })
        }
        // console.log(fileList)
        this.fileList = fileList
      },
      setParams(record) {
        this.newParams = record
      },
      handleCancel() {
        this.previewVisible = false
      },
      // 查看
      handlePreview(file) {
        console.log(file)
        const { fileName, thumbUrl, url } = file
        let isPic = fileName.IsPicture()
        if (isPic || this.uploadFileType == 'video' || this.uploadFileType == 'audio') {
          this.imageName = file.srcFileName
          this.imageUrl = thumbUrl || url
          this.previewVisible = true
        } else if (file.type == 'pdf') {
          window.open(file.url)
        }
      },
      removeOption(file) {
        console.log('删除', file)
        const that = this
        that.$confirm({
          title: '提示',
          content: '确认删除当前文件',
          okText: '确认',
          cancelText: '取消',
          onOk() {
            file.status = 'removed'
            file.uid = uidGenerator()
            that.handleDelete(file)
          },
          onCancel() {}
        })
        return false
      },
      //处理下文件的回头显示
      beforeUpload(file) {
        // console.log(file)
        this.uploadGoOn = true
        let fileType = file.type // 上传类型
        let types = [] // 判断格式
        let str = ''
        if (this.uploadFileType == 'image') {
          types = this.cheackOptions.image
          str = '文件格式有误,仅支持jpg,jpeg,png,格式文件上传'
        } else if (this.uploadFileType == 'file') {
          types = this.cheackOptions.document
          str = '文件格式有误,仅支持pdf,doc,docx,xls,xlsx,ppt,pptx,格式文件上传'
        } else if (this.uploadFileType == 'video') {
          types = this.cheackOptions.video
          str = '文件格式有误,mp4,格式文件上传'
        } else if (this.uploadFileType == 'audio') {
          types = this.cheackOptions.audio
          str = '文件格式有误,仅支持wav格式文件上传'
        }
        const isJpgOrPng = types.some(res => {
          if (res == fileType) {
            return true
          }
        })
        if (!isJpgOrPng) {
          this.uploadGoOn = false
          this.$message.error(str)
          return false
        }
        return isJpgOrPng
      },
      handleChangeImage(info) {
        // console.log('--文件列表改变--', info)
        const { file } = info
        if (file) {
          const { status, response, uid } = file
          if (status == 'done') {
            if (!response.success) {
              info.fileList = info.fileList.filter(i => i.uid != uid) // 过滤上传失败的文件
            }
          }
        }
        if (!info.file.status && this.uploadGoOn === false) {
          info.fileList.pop()
        }
        let fileList = info.fileList
        if (info.file.status === 'done') {
          if (this.number > 0) {
            fileList = fileList.slice(-this.number)
          }
          if (info.file.response.success) {
            fileList = fileList.map(res => {
              if (res.response && res.response.data) {
                const { url, filename, ext } = res.response.data
                res.url = url
                res.srcFileName = filename
                res.fileName = filename
                res.fileType = ext
              }
              return res
            })
            // console.log(fileList)
            // this.$message.success(`${info.file.name} 上传成功!`)
          } else {
            this.$message.error(
              `${info.file.response.success ? info.file.response.message : '文件上传失败，不支持当前格式'}!`
            )
          }
        } else if (info.file.status === 'error') {
          this.$message.error(`${info.file.name} 上传失败.`)
        } else if (info.file.status === 'removed') {
          // this.handleDelete(info.file)
        }
        this.fileList = fileList
        if (info.file.status === 'done' || info.file.status === 'removed') {
          let newFileList = []
          for (let a = 0; a < fileList.length; a++) {
            if (fileList[a].status === 'done') {
              console.log(fileList[a])
              let fileObj = {
                srcFileName: fileList[a].srcFileName,
                url: fileList[a].url
              }
              newFileList.push(fileObj)
            } else {
              return
            }
          }
          this.$emit('change', newFileList)
        }
      },
      // 删除逻辑
      handleDelete(file) {
        let index = this.fileList.indexOf(file)
        let newFileList = this.fileList.slice()
        console.log(index, newFileList)
        newFileList.splice(index, 1)
        this.fileList = newFileList
        this.handleChangeImage({ file: file, fileList: this.fileList })
        this.$emit('change', null)
      },
      getBase64(img, callback) {
        const reader = new FileReader()
        reader.addEventListener('load', () => callback(reader.result))
        reader.readAsDataURL(img)
      }
    },
    model: {
      prop: 'value',
      event: 'change'
    }
  }
  </script>
  
  <style lang="less" scoped>
  /deep/ .ant-modal-body {
    padding-top: 40px;
  }
  
  .memo {
    position: absolute;
    left: 168px;
    bottom: 12px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    a {
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
    }
    span.desc {
      font-weight: 400;
      font-size: 12px;
      color: #8c8c8c;
    }
  }

  ::v-deep .ant-upload.ant-upload-select-picture-card {
    width: 154px;
    height: 74px;
    border: none;
    padding: 0;
  }
  ::v-deep .ant-upload-list-picture-card-container, ::v-deep .ant-upload-list-picture-card .ant-upload-list-item {
    width: 154px;
    height: 74px;
    border: none;
    padding: 0;
    border-radius: 4px;
    overflow: hidden;
  }
  ::v-deep .ant-upload-list-item-image {
    border-radius: 4px;
  }
  ::v-deep .ant-upload.ant-upload-select-picture-card > .ant-upload {
    padding: 0;
  }
  img.upload-img {
    width: 100%;
    height: 100%;
  }
  .img-box {
    position: relative;
    span.count {
        position: absolute;
        right: -34px;
        bottom: 0;
        font-size: 12px;
        color: #595959;
    }
  }

  ::v-deep .ant-upload-list-picture-card-container {
    border: 1px solid #E8E9EB;
  }
  </style>
  